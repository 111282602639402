// properly display the section position when user click the Contact link
const contactSection = document.getElementById('contact')
const servicesSection = document.getElementById('services')
const linkContact = document.querySelector('nav .link-contact')
const linkServices = document.querySelector('nav .link-services')

scrollToPosition(linkServices, servicesSection)
scrollToPosition(linkContact, contactSection, () => {
  document.querySelector('#contact-form #name').focus()
})

function scrollToPosition(link, section, callback = () => {}) {
  if(link != null) {
    link.addEventListener('click', function(e){
      e.preventDefault()

      section.scrollIntoView()
      window.scrollBy(0, 65)
      callback()
    })
  }
}
