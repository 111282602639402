// style highlight when the input field on focus
const form = document.getElementById('contact-form')
if(form != null) {
  const inputs = form.querySelectorAll('input, textarea')
  inputs.forEach(input => {
    input.onfocus = () => { 
      input.parentElement.classList.add('highlight') 
    }
    input.onblur = () => {
      input.parentElement.classList.remove('highlight')
    }
  })
}
