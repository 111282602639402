import "./styles/variables.scss";
import "./styles/navigation-bar.scss";
import "./styles/main.scss";
import "./styles/landing.scss";
import "./styles/iso-29110.scss";
import "./styles/service.scss";
import "./styles/mfg.scss";
import "./styles/how_we_work.scss";
import "./styles/leadership.scss";
import "./styles/technology.scss";
import "./styles/post.scss";
import "./styles/contact.scss";
import "./styles/footer.scss";

import "./js/contact_form.js";
import "./js/scrolling.js";
import "./js/mfg.js";
