const mfgProcesses = document.querySelector('.mfg-processes')
const mfgBoards = document.querySelector('.mfg-boards')

if(mfgProcesses != null) {
  console.log(mfgProcesses)
  Array.from(mfgProcesses.children).forEach(item => {
    item.addEventListener('click', e => {
      const selectedItem = e.currentTarget
      const board = getBoardBySelectedItem(selectedItem)

      clearAllToggleClass(mfgProcesses, 'highlight')
      addToggleClass(selectedItem, 'highlight')

      clearAllToggleClass(mfgBoards, 'show')
      addToggleClass(board, 'show')
    })
  })
}

function getBoardBySelectedItem(item) {
  const selectedItemId = item.id
  const selectedBoardId = selectedItemId.replace(/item/, 'board')
  const board = mfgBoards.querySelector(`#${selectedBoardId}`)
  
  return board
}

function addToggleClass(item, name) {
  if(item == null) return 
  item.classList.add(name)
}

function clearAllToggleClass(items, name) {
  Array.from(items.children).forEach(item => item.classList.remove(name))
}
